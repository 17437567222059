<template>
  <v-container fill-height>
    <estimate-header />
    <estimate-form />
  </v-container>
</template>

<script>

import EstimateHeader from './EstimateHeader'
const EstimateForm = () => ({
  component: import('@/components/EstimateForm'),
  loading: import('@/components/Loader')
})

export default {
  name: 'EstimateMain',
  components: {
    EstimateForm,
    EstimateHeader
  }
}
</script>

<style lang="scss">
  .container {
    max-width: 800px;
  }

</style>
