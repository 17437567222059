<template>
  <div
    v-if="isLoading.content"
    class="d-flex justify-center align-center"
    style="width:100%; height:100%;"
  >
    <v-progress-circular
      indeterminate
      color="primary"
    />
  </div>
  <v-card
    v-else
    flat
    class="mb-5"
  >
    <v-img
      contain
      :src="selectedContent.photo"
      class="d-flex align-center justify-center text-center"
      alt="Estimation immobilière Marseille - Aix - Toulon - Cassis - La Ciotat - Saint Cyr"
    >
      <div
        class="display-1 px-3"
        style="color:#fff;"
      >
        {{ selectedContent.pictureTitle }}
      </div>
    </v-img>
    <v-card-text class="">
      <h1 class="my-4 text-uppercase display-1">
        {{ selectedContent.title }}
      </h1>
      <p>{{ selectedContent.description }}</p>
      <h2>{{ selectedContent.subTitle }}</h2>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EstimateHeader',
  computed: {
    ...mapState(['selectedContent', 'isLoading'])
  }
}
</script>
